import React, { useState } from 'react'

// @ts-ignore
import SolidBackground from '../assets/images/solid-background.png'

interface Props {
    videoSrc: string
    paragraphs: string[]
}

const BackgroundVideo: React.FC<Props> = ({ paragraphs, videoSrc }) => {
    const [textStage, setTextStage] = useState<number>(
        typeof window !== 'undefined' && window.innerWidth > 700 ? 0 : paragraphs.length - 1
    )

    return (
        <div className="bg-video">
            <video
                src={videoSrc}
                poster={SolidBackground}
                loop
                autoPlay
                muted
                playsInline
                controls={false}
            />

            <div className="bg-video-overlay" />

            <div className="bg-video-text">
                {paragraphs
                    .filter((_p, i) => i <= textStage)
                    .map((p, i) => (
                        <p key={i}>{p}</p>
                    ))}
                {textStage + 1 < paragraphs.length && (
                    <p className="bg-video-expand" onClick={() => setTextStage(textStage + 1)}>
                        ( . . . )
                    </p>
                )}
            </div>
        </div>
    )
}

export default BackgroundVideo
