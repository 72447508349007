import React from 'react'
import { graphql, PageProps } from 'gatsby'

import BackgroundVideo from '../components/BackgroundVideo'
import Layout from '../Layout'
import SEO from '../SEO'

interface Props extends PageProps {
    data: {
        sanityHome: {
            paragraphs: string[]
            backgroundvideo_url: string
        }
    }
}

const Home: React.FC<Props> = ({ data }) => {
    const { paragraphs, backgroundvideo_url } = data.sanityHome

    return (
        <div>
            <Layout currentPage="home" transparentNavbar>
                <SEO title="Was Wa(h)r" />
            </Layout>

            <BackgroundVideo paragraphs={paragraphs} videoSrc={backgroundvideo_url} />
        </div>
    )
}

export const query = graphql`
    query {
        sanityHome {
            paragraphs
            backgroundvideo_url
        }
    }
`

export default Home
